import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook } from "@fortawesome/free-brands-svg-icons"
import Layout from "../components/layout"
import styled, { keyframes } from "styled-components"
import bmwLights from "../images/bmw4.jpg"


const Section = styled.section`
  height: 70vh;
  color: whitesmoke;
  > :nth-child(1) {
    font-size: 2em;
    position: relative;
    top: 5%;
    background: rgb(51, 160, 209);
    background: linear-gradient(
      90deg,
      rgba(51, 160, 209, 1) 26%,
      /* rgba(55, 52, 133, 1) 38%, */
      rgba(55, 52, 133, 1) 46%,
      rgba(197, 43, 48, 1) 60%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: ${fadeout} 2s 4s forwards ease-in;
    text-align: center;
    @media (max-width: 768px) {
      font-size: 1em;
    }
  }
  > :nth-child(2) {
    position: relative;
    top: 5%;
    font-size: 2em;
    opacity: 0.6;
    background: rgb(51, 160, 209);
    background: linear-gradient(
      90deg,
      rgba(51, 160, 209, 1) 6%,
      rgba(55, 52, 133, 1) 38%,
      rgba(55, 52, 133, 1) 49%,
      rgba(197, 43, 48, 1) 55%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    opacity: 0;
    animation: ${fadeIn} 2.4s 2s forwards ease-in;
    text-align: center;
    @media (max-width: 768px) {
      font-size: 1em;
    }
  }
  > :nth-child(3) {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    opacity: 0;
    animation: ${fadeIn} 2.4s 2s forwards ease-in;
    > :nth-child(2) {
      display: block;
      text-align: center;
      transition: transform 1s;
      &:hover {
        transform: scale(1.2, 1.2);
      }
    }
    a {
      &:hover {
        > :nth-child(2) {
          width: 45px;
          height: 45px;
        }
      }
    }
  }
`

const BackGround = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: -10000;
  background-image: url(${bmwLights});
  background-size: cover;
  animation: ${fadeInBack} 1.4s forwards ease-in;
`

const IconContainer = styled.div`
  a {
    text-decoration: none;
    color: rgb(51, 160, 209);
  }
`

const Fb = styled.div`
  position: relative;
  top: 100px;
`

function fadeout() {
  return keyframes`
  0%{opacity:1};
  50%{opacity:0.5};
  100%{opacity: 0};
`
}

function fadeIn() {
  return keyframes`
  0% { opacity:0;};
  50% { opacity:0.5};
  100% { opacity:1;};
`
}

function fadeInBack() {
  return keyframes`
  0% { opacity:0;};
  50% { opacity:0.15};
  100% { opacity:0.3;};
`
}

const IndexPage = () => (
  <Layout>
    <BackGround></BackGround>
    <Section>
      <h2>Chcesz jak najszybciej wrócić do jazdy?</h2>
      <h2>Odwiedź najbardziej doświadczony serwis!</h2>
      <Fb>
        <div>Chcesz być na bieżąco?</div>
        <IconContainer>
          <a href="https://www.facebook.com/autoserwis.alicja" target='_blank' rel="noreferrer">
            <div>Polub nasz fanpage!</div>
            <div
              style={{
                borderRadius: "50%",
                // WebkitBorderRadius: '50%',
                backgroundColor: "white",
                width: "34px",
                height: "34px",
                margin: "auto",
              }}
            >
              <FontAwesomeIcon
                icon={faFacebook}
                style={{
                  color: "rgb(12, 138, 240)",
                  width: "35px",
                  height: "35px",
                  borderRadius: "50%",
                  WebkitBorderRadius: '50%',
                  backgroundColor: 'white',
                  verticalAlign: 'middle',
                  textAlign: 'center'
                }}
              />
            </div>
          </a>
        </IconContainer>
      </Fb>
    </Section>
  </Layout>
)

export default IndexPage
